@import url('https://fonts.googleapis.com/css?family=Nunito:300,400,600,700&display=swap');
:root{
  --font-family: 'Nunito', sans-serif;
}
.main1_container {
  margin-top: 16px;
  padding: 30px 25px 30px 25px;
  height: fit-content;
  background-color: white;
  @media (max-width: 1200px) {
    padding: 30px 20px;
  }
  @media only screen and (min-width: 541px) and (max-width: 992px) {
    padding: 20px;
  }
  @media only screen and (min-width: 320px) and (max-width: 540px) {
    padding: 20px 0px;
  }
}
.main_container {
  border-radius: 20px;
  padding: 30px 0px 30px 100px;
  display:flex;
  align-items: center;
  justify-content: center;
  height: fit-content;
  background-color: #f9fbfa;
  @media (max-width: 1200px) {
    padding: 30px 100px 50px 100px;
  }
  @media only screen and (min-width: 541px) and (max-width: 992px) {
    padding: 20px 50px 20px 50px;
  }
  @media only screen and (min-width: 320px) and (max-width: 540px) {
    padding: 10px 20px 10px 20px;
  }
}

.text_container {
  padding-top: 30px;
  padding-bottom: 30px;
  position: relative;
  z-index: 1;

  .experience_text {
    display: flex;
    font-family:  "Montserrat", sans-serif;
    font-weight: 800;
    font-size: 2.6rem;
    color: #4a4a4a;
    letter-spacing: 1.1px;
    line-height: 50px;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    margin-top: 0;

    @media (max-width: 768px) {
     width: fit-content;
    }
  }

  .volunteer_text {
    display: flex;
    font-family: var(--font-family);
    font-size: 1rem;
    font-weight: 600;
    color: #989898;
    letter-spacing: 0.12px;
    margin-top: 20px;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    line-height: 26px;
  }
}
.img_div {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  @media (max-width: 1200px) {
    justify-content: center;
    align-items: center;
  }
  @media only screen and (min-width: 541px) and (max-width: 992px) {
    justify-content: center;
    align-items: center;
  }
  @media only screen and (min-width: 320px) and (max-width: 540px) {
    justify-content: center;
    align-items: center;
  }
}
.img_container {
  width: 541px;
  height: 445px;

    @media (max-width: 1200px) {
        width: 400px;
        height: 300px;
    }
    @media only screen and (min-width: 541px) and (max-width: 992px) {
        width: 300px;
        height: 200px;
    }
    @media only screen and (min-width: 320px) and (max-width: 540px) {
        width: 200px;
        height: 100px;
    }

  .img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.div_col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  margin-top: 10px;

  .div{
    width: 100%;
    display: flex;
    align-items: flex-start;
    gap: 10px;
  }
}

.input {
  width: 100%;
  height: 40px;
  border-radius: 4px;
  border: 1px solid #ddd;
  padding: 0px 10px;
  font-family: var(--font-family);
  font-size: 15px;
  font-weight: 600;
  color: #606060;
  outline: none;
  &:focus {
    border: 1px solid var(--primary);
  }
}

.div_container {
  display: flex;
  // flex-direction: row;
  flex-wrap: wrap;
  margin-top: 10px;

  .div {
    cursor: pointer;
    display: flex;
    padding: 15px 0px 30px 0px;
    flex-direction: column;
    justify-content: space-between;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    // justify-content: center;
    font-family: var(--font-family);
    // align-items: center;
    max-width: 160px;
    width: 155px;
    height: 100px;
    color: #606060;
    text-align: center;
    white-space: normal;
    font-size: 15px;
    line-height: 20px;
    font-weight: 600;
    background: #fff;
    border-radius: 4px;
    border: 2px solid #ddd;
    margin-right: 10px;
    margin-top: 10px;
    &:hover {
      background: #fff;
      border: 2px solid var(--primary);
    }
  }

  .divgraduation{
    cursor: pointer;
    display: flex;
    flex-direction: column;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    justify-content: center;
    font-family: var(--font-family);
    align-items: center;
    max-width: 160px;
    width: 150px;
    height: 150px;
    color: #606060;
    text-align: center;
    white-space: normal;
    font-size: 12px;
    line-height: 20px;
    font-weight: 600;
    background: #fff;
    border-radius: 4px;
    margin-right: 10px;
    margin-top: 10px;
    &:hover {
      background: #fff;
      border: 2px solid var(--primary);
    }
  }
  .isIncluded{
    border: 2px solid #2f7264 !important;
  }
}
