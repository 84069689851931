.active {
  background: "grey";
}

@media only screen and (max-width: 600px) {
  .job-reads {
    display: flex;
    flex-direction: column;
  }

  .b {
    margin-top: 10px;
  }
}

.change_select_width {
  width: 100%;
}
.change_Input_width {
  width: 70% !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
.step_2_margin {
  margin-bottom: 35px;
}

@media only screen and (max-width: 991px) {
  .change_select_width {
    width: 100%;
  }
  /* .change_Input_width{
    width: 100% !important;
} */
}
@media only screen and (max-width: 1040px) {
  .step_2_margin {
    margin-bottom: 53px;
  }
}
@media only screen and (max-width: 1020px) {
  .step_2_margin {
    /* margin-bottom: 35px; */
  }
}

.image-upload > input {
  display: none;
  margin: 0;
  padding: 0;
}

.image-upload {
  display: inline;
  padding: 0;
  margin: 0;
}

.hov:hover {
  color: #145388;
}
.ok-button-color {
  background-color: #4267b2 !important;
  color: white;
}

.parentHov:hover .hovme {
  color: #145388 !important;
}

.analyz-button {
  width: 200px;
  height: 50px;
  font-size: 18px;
  color: white;
  border: none;
  background-color: #4267b2;
  border-radius: 0px;
}

.analyz-button:hover {
  background-color: #4267b2;
}

textarea {
  resize: none;
}

.position-relative img {
  border-top-left-radius: 0.7rem;
  border-top-right-radius: 0.7rem;
}
.toast-success {
  background-color: green !important;
}

.toast-error {
  background-color: red !important;
}
.select-boxx .MuiInput-root {
  width: 80% !important;
}
.select-boxx2 .MuiFormControl-root {
  width: 100% !important;
}
.change-font-size .MuiFormLabel-root {
  font-size: 0.9rem;
}

.change-font-size .input-f {
  width: 49%;
}

@media only screen and (max-width: 1220px) {
  .change-font-size .input-f {
    width: 100%;
  }
}
.try_sample button:hover {
  color: white;
  padding: 10;
  font-weight: "bold";
  border-radius: 0px;
  /* border: 1.5px solid #1377E1; */
  background-color: #4267b2;
}

.try_sample button {
  color: white;
  padding: 10;
  font-weight: "bold";
  border-radius: 0px;
  /* border: 1.5px solid #1377E1; */
  background-color: #4267b2;
}
/* .review-butn:hover{
        background-color: white !important;
        color: #1377E1 !important; */
/* } */

.change_get_button button {
  background-color: #4267b2 !important;
}

.icon1:hover {
  color: #4267b2;
  cursor: pointer;
}
.icon2:hover {
  color: #4267b2;
  cursor: pointer;
}
.icon3:hover {
  color: #4267b2;
  cursor: pointer;
}
.saveContainer .hovdel:hover {
  color: #4267b2;
}
.loader:hover {
  color: 1377e1;
}
.resume_view_butn:hover {
  background-color: #f7f7f7;
}

.scan_main_container,
.stepper_main_container,
.select_template_main {
  font-family: "Nunito", sans-serif !important;
}
.saveContainer {
  font-family: "Nunito", sans-serif !important;
}

.change_font {
  font-family: "Nunito", sans-serif !important;
}

.stepper_main_container .MuiStepLabel-label.MuiStepLabel-alternativeLabel {
  /* font-family: 'Nunito', sans-serif !important; */
  /* font-weight: 600; */
}

.stepper_main_container .MuiSvgIcon-root {
  width: 3rem !important;
  height: 3rem !important;
}
.saveContainer .MuiSvgIcon-root {
  width: 1.1rem !important;
  height: 1.1rem !important;
}
.stepper_main_container .MuiStepConnector-alternativeLabel {
  top: 25px !important;
  left: calc(-50% + 34px) !important;
  right: calc(50% + 34px) !important;
}
.stepper_main_container .MuiStepIcon-root.MuiStepIcon-active {
  color: #4267b2 !important;
}
.stepper_main_container .MuiStepIcon-root.MuiStepIcon-completed {
  color: #8DB48E !important;
}
/* .MuiStepIcon-root */
.MuiStepLabel-active .MuiStepLabel-label {
  color: #4267b2 !important;
}
.MuiStepLabel-completed .MuiStepLabel-label {
  color: #8DB48E !important;
}

.set_mark .MuiSvgIcon-root {
  width: 1.5rem !important;
  height: 1.5rem !important;
}
.change_get_button button {
  background-color: #4267b2 !important;
  border: 0 !important;
}
.file_input_field {
  width: 70% !important;
}
.scan-upload {
  color: #1377e1;
  cursor: pointer;
  padding-left: 70% !important;
  padding-top: 3px !important;
  padding-bottom: 3px !important;
  padding-right: 70% !important;
  margin: 0px;
  font-size: 16px;
  background-color: #4267b2;
}
@media only screen and (max-width: 991px) {
  .file_input_field {
    width: 83% !important;
  }
}
@media only screen and (max-width: 917px) {
  .file_input_field {
    width: 81% !important;
  }
}
@media only screen and (max-width: 812px) {
  .file_input_field {
    width: 78% !important;
  }
}
@media only screen and (max-width: 767px) {
  .file_input_field {
    width: 90.5% !important;
  }
}
@media only screen and (max-width: 719px) {
  .file_input_field {
    width: 89% !important;
  }
}
@media only screen and (max-width: 626px) {
  .file_input_field {
    width: 87% !important;
  }
}
@media only screen and (max-width: 518px) {
  .file_input_field {
    width: 85% !important;
  }
}

.autocomplete-mainContainer
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
  padding: 0px !important;
}

.autocomplete-mainContainer
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]
  .MuiAutocomplete-input {
  padding: 6.5px 9px !important;
}

.autocomplete-mainContainer .MuiSvgIcon-fontSizeSmall {
  width: 1.3rem !important;
  height: 1.3rem !important;
}

.blinkingIcon {
  animation: _blinkingIcon 4s infinite;
  width: 100;
  background-color: "red";
}
@keyframes _blinkingIcon {
  2% {
    color: transparent;
  }
  49% {
    // color: #ff7f26;
  }
  // 50% {
  //   color: transparent;
  // }
  // 90% {
  //   color: transparent;
  // }
  100% {
    // color: #ff7f26;
  }
}
.color-black {
  color: #0000;
}
