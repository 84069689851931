@media only screen and (max-width: 1140px) {
  .nav {
    display: block;
  }

  .card-header-tabs {
    margin: "auto";
    text-align: "center";
  }
}

@media only screen and (max-width: 400px) {
  .btnsm {
    height: 40px;
    width: 150px;
    margin-left: 0 !important;
  }
}

.nav-tabs .nav-link.active::before,
.nav-tabs .nav-item.show .nav-link::before {
  content: " ";
  background: #145388;
  border-radius: 10px;
  position: absolute;
  width: 6rem;
  height: 5px;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.set-nav-links-cont a {
  font-size: 0.75rem;
}

.set-nav-links-cont .input-group-text {
  font-size: 0.7rem;
}

.set-nav-links-cont input {
  font-size: 0.7rem;
}

.set-nav-links-cont .nav-tabs .nav-link.active {
  background-color: transparent;
}

/* .tabs-container #vertical-tabpanel-0,#vertical-tabpanel-1,#vertical-tabpanel-2,#vertical-tabpanel-3,#vertical-tabpanel-4{
    margin: auto !important;
    width: 80%;
} */
.tabs-container .MuiBox-root {
  /* padding: 0 !important; */
}

.change_font_style {
  font-family: "Nunito" !important;
}
.change_button_color button {
  background-color: #4267b2;
  border: none;
}
.tabs-container {
  background-color: #8DB48E;
}
.save_education_butn {
  background-color: #4267b2 !important;
  border: none !important;
}
.delete_account_butn {
  background-color: #4267b2 !important;
  border: none !important;
  border-radius: 5 !important;
}
.tagsInput {
  width: 100%;
  border: 1px solid #ccc;
  // height: 80px;
  padding: 5px;
}
