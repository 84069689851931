//
// user.scss
//
.login-page {
    font-size: 15px;
    .forgot-pass {
        z-index: 99;
        position: relative;
        font-size: 13px;
        cursor: pointer;
    }
} 

@media (min-width: 701px) and (max-width: 990px) {
    .md_LoginForm{
        width: 400px !important;
        position: relative !important;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto !important;
        height: 100vh !important;
     }
     .md_SignUpForm{
        width: 500px !important;
        position: relative !important;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto !important;
     }
     .specficMargin{
         margin-bottom: 40px;
         background-color: red;
     }
}




.cover-user{
    .container-fluid{
        max-width: 100%;
        .padding-less.img {
            background: scroll center no-repeat;
            background-size: cover;
            position: absolute;
            height: 100%;
        }
    }    
    // .cover-user-img{
    //     height: 100vh;
    // }
}

@media (min-width: 990px) {
    .cover-user {
        .cover-user-img{
            padding: 0px 20%;
        }  
    }
}

@media (max-width: 701px) {
    .cover-user {
        .padding-less {
            position: relative !important;
            height: 400px !important;
        }
        .cover-user-content {
            text-align: left !important;
        }    
        
    }
}

@media (max-width:701px) {
    .cover-user {
        .cover-user-img {
            height: auto !important;
        }
       
    }
}