//
// contact.scss
//
.contact-detail {
    .icon {
        img {
            height: 70px;
        }
    }
    .content {
        .title {
            font-size: 20px;
        }
    }
}

.form-group {
    .icons {
        position: absolute;
        top: 0;
        left: 0;
        line-height: 100px;
        font-size: 18px;
    } 
}

.map {
    iframe {
        width: 100%;
        height: 400px;
    }
}

.error {
    margin: 8px 0px;
    display: none;
    color: $danger;
}
  
#ajaxsuccess {
    font-size: 16px;
    width: 100%;
    display: none;
    clear: both;
    margin: 8px 0px;
}

.error_message {
    padding: 10px;
    margin-bottom: 20px;
    text-align: center;
    border: 2px solid $danger;
    color: $danger;
    border-radius: 10px;
    font-size: 14px;
}

.contact-loader {
    display: none;
}
  
#success_page {
    text-align: center;
    margin-bottom: 50px;
    h3 {
        color: $success;
        font-size: 22px;
    }
}

//Flatpicker 
.flatpickr-day {
    &.selected,  
    &.selected:hover {
        background: $primary;
        border-color: $primary;
    }
}

@media (min-width: 768px) {
    .map {
        &.map-height-two {
            iframe {
                height: 551px;
            }
        }
    }
}