@media screen and (max-width: 800px) {
  .smallscreen {
    display: none;
  }
}

.itemanimate {
  transition: all 0.6s ease-out;
  opacity: 1;
}

.removeanimate {
  opacity: 0;
}

.scan_result_main_container {
  font-family: "Nunito", sans-serif !important;
}
.scan_result_main_container .MuiSvgIcon-root {
  width: 0.9rem !important;
  height: 0.9rem !important;
}
.hoveld {
  color: #4267b2;
  align-items: right;
  margin-right: -5px;
}
.hoveld:hover {
  color: #4267b2;
  cursor: pointer;
}
.scrollBar {
  overflow-y: scroll;
}
.highlight {
  background-color: #4267b2;
  color: white;
  padding: 2px;
}

.highlight2 {
  background-color: #4267b2;
  color: white;
  padding: 2px;
}
.YourHighlightClass {
  background-color: #4267b2;
  color: white;
}
.white-space-pre {
  white-space: pre;
}
.footer-save-button {
  background-color: #4267b2 !important;
  border: 0px !important;
}
.hard_skill {
  background-color: #8DB48E;
  border-radius: 50px;
  color: white;
  display: block;
  width: 45px !important;
  font-size: 12px;
  text-align: center;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-right: 7px;
  padding-left: 7px;
}
.soft_skill {
  background-color: #6A8CAF;
  border-radius: 50px;
  color: white;
  display: block;
  width: 45px !important;
  text-align: center;
  font-size: 12px;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-right: 7px;
  padding-left: 7px;
}
.other_skill {
  background-color: #4267b2;
  border-radius: 50px;
  color: white;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-right: 7px;
  padding-left: 7px;
}

.chart-main-container .chartjs-render-monitor {
  /* height: 500px !important;
    width: 100% !important; */
}

.dashoboard-left-card-Container .card-body {
  /* padding: 10px !important; */
}
