@import url('https://fonts.googleapis.com/css?family=Nunito:300,400,600,700&display=swap');
:root{
  --font-family: 'Nunito', sans-serif;
}
.mainClass {
  padding: 40px 25px;
  height: fit-content;
  background-color: #f9fbfa;
  @media (max-width: 1200px) {
    padding: 30px 20px;
  }
  @media only screen and (min-width: 541px) and (max-width: 992px) {
    padding: 20px;
  }
  @media only screen and (min-width: 320px) and (max-width: 540px) {
    padding: 20px 0px;
  }
}
.main_container {
  border-radius: 20px;
  padding: 40px 100px 40px 100px;
  height: fit-content;
  background-color: #f9fbfa;
  @media (max-width: 1200px) {
    padding: 30px 100px 30px 100px;
  }
  @media only screen and (min-width: 541px) and (max-width: 992px) {
    padding: 20px 50px 20px 50px;
  }
  @media only screen and (min-width: 320px) and (max-width: 540px) {
    padding: 10px 20px 10px 20px;
  }
}
.text_container {
  text-align: center;
  font-size: 40px;
  color: #4a4a4a;
  line-height: 50px;
  letter-spacing: 1.1px;
  margin-bottom: 43px;
  font-family:  "Montserrat", sans-serif;
  font-weight: 700;
  @media (max-width: 768px) {
    font-size: 30px;
    line-height: 40px;
  }

  @media (max-width: 540px) {
    font-size: 25px;
    line-height: 35px;
  }

  @media (max-width: 320px) {
    font-size: 20px;
    line-height: 30px;
  }
}
.card_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 50px;

  @media (max-width: 768px) {
    .card {
      margin-top: 20px;
    }
    flex-direction: column;
  }
}
.card {
  position: relative;
  max-width: 460px;
  width: 100%;
  height: 100%;
  min-height: 180px;
  border: 2px solid #ddd;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    border: 2px solid var(--primary);
  }

  @media only screen and (min-width: 320px) and (max-width: 992px) {
    max-width: 100%;
  }
}

.container_img {
  min-height: 245px;
  background: rgba(222, 108, 108, 0.1)
    url(/resume-builder/app/images/hts-create-resume.png) no-repeat center
    bottom;
  background-size: 60%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  .container_img_sub {
    width: 280px;
    height: 100%;
    position: absolute;
    transform: translate(0%, 16%);
  }
  .container_img_sub1 {
    width: 280px;
    height: 100%;
    position: absolute;
    transform: translate(0%, 9%);
  }
}

.text_wrapper {
  padding: 3px 3px;
  text-align: center;
}
.resume_box_text {
  width: 100%;
  font-size: 1rem;
  color: #989898;
  line-height: 22px;
  letter-spacing: 0.12px;
  font-family: var( --font-family);
  margin: 0 auto;
  font-weight: 500;
}
.resume_text {
  font-size: 21px;
  font-family: var( --font-family);
  font-weight: 700;
  color: #545454;
  line-height: 33px;
  max-width: 100%;
}
.badge {
  position: absolute;
  z-index: 2;
  font-weight: 700;
  font-family: var(--font-family);
  color: white;
  width: 124px;
  height: 25px;
  top: -1px;
  left: -1px;
  background: var(--primary);
  border-radius: 9px 0 9px 0;
  font-size: 12px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
