// @import '../../theme/colors';

.unselectedDragItems .dragBox {
    border: 0px dashed rgba(23, 247, 45, 0.982);
    transition: all 0.2s ease-in-out;
}
.unselectedDragItems .dragBox:hover {
    border: 0px dashed #22ff04;
    transition-delay: 0.1s;
}
.dragBoxIcon {
    text-align: center;
}
.unselectedDragItems .dragBox:hover > .dragBoxIcon {
    transition-delay: 0.2s;
    // display: block;
    opacity: 1;
}

.selectedDragItems .dragBox {
    border: 0px dashed rgb(89, 255, 0);
}

.dndIcon {
    color: #fff;
    font-size: 20px;
    margin-top: 2px;
}
