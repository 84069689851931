$color: #f95e5e;
/* Red */

.checkLabel {
  cursor: pointer;

  &:before {
    display: inline-block;
    content: "+";
    margin: 0 10px 0 0;
    width: 20px;
    height: 20px;
    border: 1px solid white;
    border-radius: 100%;
    vertical-align: middle;
    color: green;
    font-size: 30px;
    text-align: center;
    font-weight: 400;
    line-height: 0.5em;
    transition: transform 0.2s ease-in-out;
  }
}
.css-1wa3eu0-placeholder {
  top:55% !important;
}
input[type="checkbox"]:checked + label:before {
  background: white;
  color: $color;
  transform: rotate(135deg);
  transform-origin: center center;
}

// input[type=checkbox] {
//     visibility: hidden;
// }

.activeCheck {
  color: green !important;
}

label {
  font-size: 12px;
  color: grey;
}

.toast-success {
  background-color: green !important;
}

.toast-error {
  background-color: red !important;
}

.job-matcher-form .react-tagsinput {
  width: 100% !important;
}

.job_matcher_main_container {
  font-family: "Nunito", sans-serif !important;
}