// @import '../../theme/colors';

.contentEditableContainer {
  padding: 4px 2px;
  white-space: pre-line;
  // border: 1px dashed #fff;
  border-radius: 4px;
  outline: 0;
}

.contentEditableContainer:focus {
  // border: 1px dashed #a1a1a1;
  border-radius: 4px;
  background-color: #efefef;
  outline: 0;
}
.contentEditableContainer:active {
  // border: 1px dashed #a1a1a1;
  border-radius: 4px;
  outline: 0;
}
.contentEditableContainer:visited {
  // border: 1px dashed #a1a1a1;
  border-radius: 4px;
  outline: 0;
}
input[type='date']:in-range::-webkit-datetime-edit-year-field,
input[type='date']:in-range::-webkit-datetime-edit-month-field,
input[type='date']:in-range::-webkit-datetime-edit-day-field,
input[type='date']:in-range::-webkit-datetime-edit-text {
  color: #999;
}
