//
// price.scss
//
.pricing-rates {
  position: relative;
  z-index: 1;
  border-radius: 3rem;
  border-bottom: 3px solid $gray-500 !important;
  .title {
    font-weight: 700;
    font-size: 16px;
  }
  .feature {
    .feature-list {
      margin: auto auto 5px;
      font-size: 14px;
    }
  }
  &.business-rate {
    transition: all 0.3s ease-in-out;
    &:hover {
      .title {
        color: $primary;
      }
      transform: scale(1.03);
      z-index: 2;
      border-color: $primary;
    }
  }
  &.starter-plan {
    transform: scaleY(1.1);
    z-index: 2;
    border-color: $primary;
  }
  &.best-plan {
    transition: all 0.5s ease;
    .lable {
      width: 200px;
      position: absolute;
      top: 15px;
      right: -65px;
      transform: rotate(45deg);
      z-index: 1;
      h6 {
        font-size: 12px;
      }
    }
    &:hover {
      transform: translateY(-10px);
    }
  }
}

@media (max-width: 767px) {
  .pricing-rates {
    &.starter-plan {
      transform: scaleY(1);
    }
  }
}
.value-label {
  background-color: #FFBE61;
  width: 200px;
  padding-top: 2px;
  padding-bottom: 2px;
  text-align: center;
  padding-left: 30px;
  color: #ffff;
  font-weight: bold;
  position: absolute;
  right: -30px;
  top: 10px;
  transform: rotate(30deg);
}
