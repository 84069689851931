.manager-aWrapper {
  padding: 40;
  background-color: white;
  font-family: sans-serif;
  max-width: 1080px;
  margin: auto;
}
.manager-template-row {
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  display: flex;
}
.manager-template-col {
  flex-direction: column;
  display: flex;
}
.manager-template-text-center {
  align-items: center;
}

.manager-template-row > div > div {
  width: inherit;
}
.manager-template-row > div {
  width: 100%;
}

.manager-template-text-bold {
  font-weight: bold;
}
.manager-template-wrapper {
  padding: 30px;
  background-color: white;
  font-family: sans-serif;
  max-width: 100vw;
  width: 88vw;

  margin: auto;
}

.manager-fTitle {
  font-weight: 700;
  font-size: 1.2em;
  padding: 0;
  line-height: 20px;
  text-align: center;
}
.fullName {
  font-weight: 800;
  font-size: 2em;
  padding: 0;
  line-height: 30px;
  text-align: center;
}

.fullNameNew {
  font-weight: 800;
  color:white !important;
  font-size: 2em;
  padding: 0;
  line-height: 30px;
  text-align: center;
}

.manager-f-sub-rowA {
  margin: 0 auto;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.full-width {
  width: 100%;
}

.manager-fName {
  font-size: 0.95em !important;
  line-height: 20px;
  margin-top: 2px;
  line-height: initial;
  white-space: nowrap;
  overflow: auto;
}
.skill-nowrap {
  white-space: normal !important;
}

.manager-heading {
  justify-content: flex-start;
  //   height: 80px;
  width: 100%;
  // border-top: 3px solid gray;
  //   margin-top: 20px;
  padding-top: 10px;
}

.manager-border__top {
  // border-top: 1px solid rgba($color: #000000, $alpha: 0.1);
}

.manager-fHeading-title {
  border-bottom: 2px solid black !important;
  font-size: 1.5em;
  font-weight: 800;
  text-transform: uppercase;
  border-radius: 0px !important;
  width: fit-content;
}
.manager-fHeading-title1 {
  border-bottom: 2px solid black !important;
  font-size: 1.5em;
  font-weight: 800;
  text-transform: uppercase;
  border-radius: 0px !important;
}
.manager-mb-bottom-0 {
  margin-bottom: 0 !important;
}
.a4Paper {
  // background-color: red;
  width: 80%;

  padding: 20px !important;
  margin: auto;
  border: 2px solid rgba($color: #000000, $alpha: 0.05);
  padding: 5px;
}
