.job-matcher-proTip {
    display: flex;
    justify-content: center;
    gap: 20px;
}

.proTip-container {
    width: fit-content;
    border: 1px solid #ccc;
    padding: 10px 10px 10px;
    height: fit-content;
    margin-top: 7px;
}

@media screen and (max-width: 930px) {
    .job-matcher-proTip {
        flex-direction: column-reverse;
        align-items: center;
    }

 
}