.PrivateTabIndicator-colorSecondary-29 {
  background-color: #1376e1 !important;
}
/* .account-container #vertical-tabpanel-0,#vertical-tabpanel-1{
    margin: auto !important;
    width: 80%;
} */

.account-container .makeStyles-root-1 {
  height: 400px;
}

/* .account-container #vertical-tabpanel-0,#vertical-tabpanel-1{
    margin: auto !important;
    width: 80%;
} */

.change_font_style {
  font-family: "Nunito" !important;
  /* font-style: italic; */
}

.pass_change_input {
  width: 80% !important;
}
@media screen and (max-width: 768px) {
  .pass_change_input {
    width: 100% !important;
  }
}
