// .modal-content{
//     border-radius: 0px !important;
// }
.editorClassName {
  background-color: white;

  height: 350px !important;
}
.skills-and-keywords {
  border: 1px solid #4267b2;
  width: 100%;
  height: 230px;
  overflow: hidden;
}
.skills-content {
  overflow-y: scroll;
  width: 100%;
  height: 189px;
  background-color: white;
}
.skills-and-keywords .skills-header {
  height: 40px;
  width: 100%;
  color: white;
  display: flex;
  align-items: center;
  padding-left: 15px;
  background-image: linear-gradient(to right, #4267b2, #9ca5b8);
}

.linear-background {
  background-image: linear-gradient(to right, #4267b2, #9ca5b8);
}
.linear-background .close {
  color: white;
  position: relative;
  top: 8px;
}
/* job-description */

.job-description {
  border: 1px solid black;
  width: 100%;
  height: 230px;
  overflow: hidden;
}

.job-description .job-desc-header {
  height: 40px;
  width: 100%;
  color: white;
  display: flex;
  align-items: center;
  padding-left: 15px;
  background-color: black;
}
.job-content {
  overflow-y: scroll;
  width: 100%;
  height: 189px;
}

.Edition_col_cont {
  position: relative;
  bottom: 86px;
}
.job-desc-col {
  margin-top: 15px;
}

@media screen and (max-width: 991px) {
  .skills-and-keywords {
    margin-top: 10px;
  }
  .Edition_col_cont {
    position: relative;
    bottom: 0px;
    top: 10px;
  }
  .job-desc-col {
    margin-top: 55px;
  }
  .upload_butn {
    margin-top: 20px;
  }
}
@media screen and (max-width: 684px) {
  .job-desc-col {
    margin-top: 80px;
  }
}
@media screen and (max-width: 616px) {
  .job-desc-col {
    margin-top: 90px;
  }
}
@media screen and (max-width: 511px) {
  .job-desc-col {
    margin-top: 115px;
  }
}

.Edition_col_cont .DraftEditor-root {
  padding: 10px;
}
