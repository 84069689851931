//
// footer.scss
//
.footer {
    background: #F8F9FC;
    padding: 60px 0;
    position: relative;
    color: $gray-500;
    
    .logo-footer {
        color: $light;
        font-weight: 700;
        font-size: 24px;
        padding: 3px 0;
        letter-spacing: 1px;
    }
    .logof{
        background-color:  #F8F9FC;
        padding-right:13px;
        // padding-left:13px;
        border-radius: 6px;
    }
    .footer-head {
        font-size: 20px; 
        letter-spacing: 1px;         
        font-weight: 500;
    }
    .foot-subscribe {
        .form-control {
            background-color: #25304e;
            border: 1px solid #25304e;
            color: $light;
            &:focus {
                box-shadow: none;
            }
        }
        input{
            &::placeholder{
                color: $gray-500;
            }
        }
    } 
    .text-foot {
        font-size: 14px;
        color: $gray-500;
    } 
    .text-link {
        text-decoration: none;
        color: #909090;
    }
    .footer-list {
        margin-bottom: 0;
        li {
            margin-bottom: 10px;
            a{
                transition: all 0.5s ease;
                &:hover {
                    color: lighten($gray-500, 20%);
                }
            }   
            &:last-child{
                margin-bottom: 0;
            }         
        }
    }    
    &.footer-bar {
        padding: 30px 0;
        .payment-cards {
            img {
                height: 25px;
            }
        }
    }
}

.lastfoot{
    background-color: #F8F9FC;
}

hr {
    margin-top: 0;
    margin-bottom: 0;
    border: 0;
    border-top: 1px solid $gray-500;
}

