.donutchart-track{
    fill: transparent;
    stroke: #DAE2E5;
    stroke-width: 26;
  }
  .donutchart-indicator {
    fill: transparent;
    stroke: #FFCC00;
    stroke-width: 26;
    stroke-dasharray: 0 10000;
    transition: stroke-dasharray .3s ease;
  }
  
  .donutchart {
    /* margin: 0 auto; */
    border-radius: 50%;
    display: block;
  }
  
  
  .donutchart-text{
    font-family: 'Roboto';
    fill: #FFCC00;
    font-weight: bold;
  }
  .donutchart-text-val{
    font-size:22px;
  }
  .donutchart-text-percent{
    font-size:14px;
  }
    /* .donutchart-text-label{
      font-size:9px;
    }
    */
  
  
  body{
    /* padding:40px; */
    font-family:'Roboto';
    /* text-align:center; */
  }
  
  /* input{
    padding:8px 10px;
    border-radius:4px;
    border:1px solid #ddd;
    width:154px;
  } */

  .dashboard-container{
    margin: auto;
    width:100%;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .recent-scan-history{
    padding: 25px;
  }
  .recent-main{
    width:100%;
    padding-bottom: 10px;
    background-color: white;
  }

  .recent-main .breadcrumb{
    background-color: white;
    margin-bottom: 0px;
  }



@media screen and (max-width: 974px) {
  .scan_history{
    min-width:400px;
  }
}

.dashboard-container .breadcrumb-item + .breadcrumb-item::before{
  content: "|";
}

/* .dashboard-container a{ */
  /* font-size: .75rem; */
  /* font-weight: 500; */
/* } */

.modelbtn1{
  background-color: transparent !important;
  border: none !important;
  color: blue !important;
}
.modelbtn2{
  background-color: transparent !important;
  border: none !important;
  color: red !important;
}
.modelbtn1:hover{
  background-color: transparent !important;
  border: none !important;
  color: blue !important;
}
.modelbtn2:hover{
  background-color: transparent !important;
  border: none !important;
  color: red !important;

}
.ok-button-color{
  background-color: #4267B2 !important;
  color: white;
}

.buy-button button:hover{
  border: 1.5px solid #1377E1;
  color:#1377E1 !important;
  background-color:white !important;
}

.scan_history_tables{
  font-family: 'Nunito', sans-serif !important;
}

.scan-history-actions-icons{
  cursor: pointer;
  font-size: 1.1rem;
  margin-right: 3px;
  margin-left: 3px;
}