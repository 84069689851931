body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.react-tagsinput-input {
  width: 100% !important;
}

.resuma-builder-main {
  font-size: 1.3vw !important;
}

.resuma-builder-main b {
  font-size: 1.3vw !important;
}

.resuma-builder-main span {
  font-size: 1.3vw !important;
}
.MuiButton-label {
  line-height: normal;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #4267b2 !important;
}

.MuiDialog-paperScrollPaper {
  overflow-y: hidden !important;
}
.dnd_dragBoxIcon__3Hk9N {
  opacity: 1 !important;
}
::placeholder {
  color: grey !important;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: grey !important;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: grey !important;
}
/* ANTD SELECT */
.ant-select-selection {
  height: 44px !important;
}
#js-licensing {
  display: none !important;
}

.emailSuggestionText {
  background: none;
    background-color: #ffff !important;
    border: none !important;
    padding: 0px !important;
}

.emailSuggestionText:hover {
  background-color: #fff !important;
    border: none !important;
    margin: 0px !important;
    padding: 0px !important;
}


.noteslistStyle {
  padding: 10px 0px 10px 5px;
  width: 100%;
  display: flex;
  cursor: pointer;
  justify-content: space-between;
}


#searchID input {
  width: 350px;
  height: 20px;
  margin: 0;
  padding: 15px;
  background: #fff;
  border: 1px solid black;
  color: #727272;
  float: left;
  font: 12px "Lucida Sans Unicode", sans-serif;
  transition: background 0.4s ease-in-out 0s;
}
#searchID button {
  width: 45px;
  height: 45px;
  text-indent: -9999em;
  /* background: url("searchIcon.jpg") #4eac10; */
  transition: background 0.3s ease-in-out 0s;
  border: 1px solid #fff;
}

#containerID {
  width: 50%;
  margin: 0 auto;
}
.ui-helper-hidden-accessible {
  display: none;
}