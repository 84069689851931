@media screen and (max-width: 800px) {
    .smallscreen {
        display: none;
    }
}

.itemanimate {
    transition: all 0.6s ease-out;
    opacity: 1;
}

.removeanimate {
    opacity: 0;
}
