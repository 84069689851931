/* Template Name: Landrick - Saas & Software Landing Page Template
   Author: Shreethemes
   E-mail: shreethemes@gmail.com
   Version: 1.0.0
   Created: August 2019
   File Description: Main Css file of the template
*/
/*********************************/
/*         INDEX                 */
/*================================
 *     01.  Variables            *
 *     02.  Bootstrap Custom     *
 *     03.  Components           *
 *     04.  General              *
 *     05.  Helper               *
 *     06.  Menu                 *
 *     07.  Home / Landing       *
 *     08.  Features / Services  *
 *     09.  Testimonial          *
 *     10.  Price                *
 *     11.  C.T.A                *
 *     12.  Blog / Careers / Job *
 *     13.  Work / Projects      *
 *     14.  Team                 *
 *     15.  User Pages           *
 *     16.  Countdown            *
 *     17.  Contact              *
 *     18.  Footer               *
 ================================*/

@import 'variables';
@import 'bootstrap-custom';
@import 'components';
@import 'general';
@import 'helper';
@import 'menu';
@import 'home';
@import 'features';
@import 'testi';
@import 'price';
@import 'cta';
@import 'blog';
@import 'work';
@import 'team';
@import 'user';
@import 'countdown';
@import 'contact';
@import 'footer';
@import 'scan';
@import 'scanResult';
@import 'saveRescan';
@import 'scanHistory';
@import 'chart';
@import 'editScan';
@import 'jobMatcher';
@import 'manage';
@import 'profile';
@import 'pages';
@import 'account';

.Typist {
  display: inline;
}
li.active > a {
  color: #2f55d4 !important;
}

.main-slider > .slides {
  margin-left: 0 !important;
  padding-left: 0 !important;
}

.nav-link {
  border-radius: 10px !important;
  // display: flex;
  align-items: center;
}

.pagination .active a {
  color: #ffffff !important;
}

a {
  &:hover {
    cursor: pointer;
  }
}

.back-to-top {
  align-items: center;
  display: flex;
  justify-content: center;
  line-height: 31px !important;
}
#mt-1 {
  margin-top: 5px !important;
}
#mb-1 {
  margin-top: 5px !important;
}

.wrapper {
  min-height: calc(100vh - 30px) !important;
}

// .PhoneInputCountryIconImg {
//   margin-top: -10px;
// }

.PhoneInputInput {
  height: 42px;
  padding: 10px;
  font-size: 13px;
  border-radius: 10px;
  border: 1px solid #dee2e6;
}

.verificationMessage {
  text-align: right;
  cursor: pointer;
}

.custom-ui {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  background-color: white;
  border-radius: 10px;
  transition: 0.3s;
  padding: 15px;
  width: 40vw;
}

.custom-ui:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.buttons {
  display: flex;
  justify-content: space-between;
}

a.button1 {
  display: inline-block;
  padding: 0.35em 1.2em;
  border: 0.1em solid #ffffff;
  margin: 0 0.3em 0.3em 0;
  border-radius: 0.12em;
  box-sizing: border-box;
  text-decoration: none;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  color: #ffffff;
  text-align: center;
  transition: all 0.2s;
}

a.button1:hover {
  color: #ffffff;
  background-color: 000000;
}

@media only screen and (max-width: 600px) {
  .custom-ui {
    width: 80vw;
  }
}

.toast-success {
  background-color: green !important;
}

.toast-warning {
  background-color: orange !important;
}

.toast-info {
  background-color: rgb(0, 149, 255) !important;
}

.toast-error {
  background-color: red !important;
}
.changeIcon .icons {
  position: absolute;
  top: 0;
  left: auto;
  right: 7px;
  line-height: 0px;
  font-size: 18px;
  margin-top: 42px;
}

.navbar-logo {
  bottom: -19px;
}
/* .top-nav-container a{
  font-size: .95rem !important;
  font-weight: 400 !important;
} */
@media screen and (max-width: 770px) {
  .navbar-logo {
    bottom: 0px;
  }
}
.cardOptimizer {
  min-height: auto;
}

@media screen and (min-width: 1044px) {
  .cardOptimizer {
    height: 200px;
  }
}
@media screen and (max-width: 1044px) and (min-width: 750px) {
  .cardOptimizer {
    height: 286px;
  }
}
/* .active {
  color: "grey";
  font-weight: 800;
} */

@media screen and (max-width: 900px) {
  .extrasmall {
    display: none !important;
  }
}

@media screen and (max-width: 770px) {
  .navbar-logo img {
    margin-top: 18px;
  }
}

@media screen and (max-width: 767px) {
  .navbar-logo img {
    margin-top: 7px;
  }
}

.change_font_style {
  font-family: 'Nunito' !important;
  /* font-style: italic; */
}
.dropdown-menu-top-nav{
 top: unset !important;

}
@media only screen and (max-width:1169) {
  .dropdown-menu-top-nav{
  transform: translate3d(-172px, 20px, 0px) !important;
  }
}
@media only screen and (min-width:1440px) {
  .dropdown-menu-top-nav{
    transform: translate3d(-172px, 32px, 0px) !important;
  }
}
@media only  screen and  (max-width:1440px) and (min-width:1170px) {
  .dropdown-menu-top-nav{
    transform: translate3d(-172px, 28px, 0px) !important;
  }
}
.f-s-19{
  font-size: 19px !important;
  }
.f-s-23{
  font-size: 23px !important;
}
.menu-button {
  display: none !important;
}

.anchor {
  margin-left: 10px;
  margin-right: 10px;
  font-weight: bold;
  color: white;
}
.my_footer_container .justify-content-center {
  height: 30px !important;
}

.hover-scale {
  transition: all 0.4s ease-in-out;
}

.hover-scale:hover {
  transform: scale(1.03);
}

.hovme:hover {
  color: #4267b2 !important;
  cursor: pointer;
}

[contenteditable='true']:empty:before {
  content: attr(placeholder);
  display: block;
  color: #aaa;
  cursor: text;
}

.customTippy {
  background-color: #fff;
  border-radius: 10px;
  -webkit-box-shadow: 0px 0px 28px 0px rgba(82, 82, 82, 1);
  -moz-box-shadow: 0px 0px 28px 0px rgba(82, 82, 82, 1);
  box-shadow: 0px 0px 28px 0px rgba(82, 82, 82, 1);
}
.customTippy .tippy-arrow {
  color: #fff;
}

.sectionTippy {
  min-width: 500px;
}

.colorTippy {
  width: 160px;
}

.typoTippy {
  min-width: 200px;
}

.imgResponsive {
  display: block;
  max-width: 100%;
  height: auto;
}

.verticalCenter {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.manage_tab_bar_cont .MuiTab-wrapper {
  letter-spacing: 0px !important;
}

//=======================

.option-bar {
  // position: fixed;
  // top: 17%;
  // flex-direction: row;
  // display: flex;
  // z-index: 99;
  // align-self: center;
  // left: 40%;

  // -webkit-transform: translateY(-50%);
  // -ms-transform: translateY(-50%);
  // transform: translateY(-50%);
}

.option-bar button {
  display: block;
  text-align: center;

  padding-left: 30px;
  padding-right: 6px;
  transition: all 0.3s ease;
  color: white;
  font-size: 20px;
  border: 0;
  z-index: 99;
  // transform: rotate(-90deg);
  font-weight: 600;
  text-transform: uppercase;
}

.option-bar button:hover {
  background-color: #4267b2;
}

.form-builder {
  color: white;
  // width: 100%;
  border-bottom: 1px solid #ffffff !important;
  // border-top-right-radius: 5px;
  // border-radius: 7px;
  margin-left: 1px;
  margin-right: 1px;
}

.template-builder {
  margin-left: 1px;
  margin-right: 1px;
  color: white;
  // border-radius: 7px;
  border-top: 1px solid #ffffff !important;
  position: relative;
}

.save-form-resume {
  color: #4267b2 !important;
  border-top: 1px solid #ffffff !important;
  border-bottom-left-radius: 7px;
  background-color: white;
}
.save-form-resume:hover {
  background-color: rgba(0, 0, 0, 0.02) !important;
}
.content {
  // margin-left: 75px;
  font-size: 30px;
}
.active__choice:hover {
  background: rgba(0, 0, 0, 0.02) !important;
}
.active__choice {
  background: white !important;
  color: #4267b2 !important;
}
.complete__choice {
  background: #8db48e !important;
  color: white !important;
}
.choice__color {
  background: #4267b2;
}
/*=====================EXTRA=====================*/

.MuiBox-root-10 {
  padding: 0 !important;
}

.MuiBox-root-24 {
  padding: 0 0 1px 0 !important;
}
.ml_15 {
  margin-left: 15px;
}
