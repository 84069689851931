@import url('https://fonts.googleapis.com/css?family=Nunito:300,400,600,700&display=swap');
:root{
  --font-family: 'Nunito', sans-serif;
}
.upload_text {
  text-align: center;
  font-size: 40px;
  color: #4a4a4a;
  line-height: 50px;
  letter-spacing: 1.1px;
  margin-bottom: 43px;
  font-family:  "Montserrat", sans-serif;
  font-weight: 900;
}

.complete_text {
  text-align: left;
  font-size: 40px;
  color: #4a4a4a;
  line-height: 50px;
  letter-spacing: 1.1px;
  margin-bottom: 43px;
  font-family:  "Montserrat", sans-serif;
  font-weight: 900;
}
.drop_zone {
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 50%;
  left: 50%;
  width: 800px;
  display: flex;
  flex-direction: column;
  padding: 40px 51px 51px;
  min-height: 156px;
  height: auto;
  border-radius: 9px;

  .drop_zone_text {
    font-size: 22px;
    font-family: var(--font-family);
    font-weight: 800;
    color: #545454;
    line-height: 33px;
    max-width: 100%;
    letter-spacing: 0.17px;
    margin-bottom: 5px;
  }
  .drop_zone_text2 {
    font-size: 0.9rem;
    font-weight: 500;
    color: #989898;
    line-height: 22px;
    letter-spacing: 0.12px;
  }
  .dropZone_Div2 {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    .drop_zone_img_div {
      width: 150px;
      height: 150px;
      margin-bottom: 22px;
      .drop_zone_img {
        width: 100%;
        height: 100%;
      }
    }
  }

  @media only screen and (min-width: 541px) and (max-width: 992px) {
    width: 100%;
  }
  @media only screen and (min-width: 320px) and (max-width: 540px) {
    width: 100%;
  }
}

.button_browse {
  color: #0f3283;
  background-color: #cbd5e1;
  border-color: #cbd5e1;
  display: inline-block;
  font-weight: 500;
  text-align: center;
  font-family: var(--font-family);
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.9rem 5rem;
  font-size: 1.1rem;
  font-weight: 700;
  line-height: 1.5;
  border-radius: 50px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.button_select {
  margin-top: 20px;
  color: #545454;
  background: #fff;
  border: 1px solid #545454;
  display: inline-block;
  font-weight: 500;
  text-align: center;
  font-family: var(--font-family);
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  padding: 0.9rem 5.4rem;
  font-size: 1.1rem;
  font-weight: 700;
  line-height: 1.5;
  border-radius: 50px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
