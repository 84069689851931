.hovdel:hover {
  color: #1377e1;
}
.print,
.delete {
  margin-left: 5px;
  margin-right: 5px;
}
.toast-success {
  background-color: green !important;
}

.toast-error {
  background-color: red !important;
}
.resume_main_container {
  font-family: "Nunito", sans-serif !important;
}

.manage_tab_bar_cont .MuiAppBar-colorDefault {
  z-index: 1;
}
.manage_tab_bar_cont .MuiTab-wrapper {
  font-size: 0.75rem !important;
  /* display: inline-block; */
  font-weight: bold;
  font-family: inherit;
  letter-spacing: 3px;
}
.wrapperClassName {
  height: 196px;
}
.image-upload > input {
  display: none;
  margin: 0;
  padding: 0;
}
.image-upload {
  display: inline;
  padding: 0;
  margin: 0;
}
.modelbtn1 {
  background-color: transparent !important;
  border: none !important;
  color: blue !important;
}
.modelbtn2 {
  background-color: transparent !important;
  border: none !important;
  color: red !important;
}
.modelbtn1:hover {
  background-color: transparent !important;
  border: none !important;
  color: blue !important;
}
.modelbtn2:hover {
  background-color: transparent !important;
  border: none !important;
  color: red !important;
}

.chart-main-container .chartjs-render-monitor {
  height: 500px !important;
  width: 100% !important;
}

.recharts-container .recharts-cartesian-axis-tick {
  font-size: 0.8rem;
  /* font-family: Roboto, sans-serif; */
}
