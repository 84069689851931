.hovi:hover {
  transform: scale(1.1);
}

.hovi {
  transition: all 0.4s ease-in-out;
}

.scan_history_main_container,
.most_recent_scan {
  font-family: "Nunito", sans-serif !important;
}

.scrollBar::-webkit-scrollbar {
  display: none;
}

.animate {
  animation: opac 0.9s ease-in-out;
}

@keyframes opac {
  from {
    opacity: 0;
  }

  to {
    acity: 1;
  }
}

.hovdel:hover {
  color: #145388 !important;

  cursor: pointer;
}
