.onboarding-main {
  position: fixed;
  top: 130px;
  right: 20px;
  z-index: 9999999;
}

.onboarding-card {
  background-color: white;
  border-radius: 7px;
  width: 0px;
  overflow: hidden;
  /* min-height: 41vh; */
  max-height: 70vh;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.onboarding-card-header {
  background-color: #4267b2;
  padding-top: 8px;
  padding-bottom: 5px;
  padding-right: 10px;
  padding-left: 10px;
}

.onboarding-card-hide {
  -webkit-animation: onboardingCardAnimationHide 0.82s
    cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  animation: onboardingCardAnimationHide 0.82s
    cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

.onboarding-card-show {
  display: visible;
  animation: onboardingCardAnimation 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97)
    both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
  width: 300px;
}
.react-sweet-progress-line {
  width: 40% !important;
}
.react-sweet-progress {
  justify-content: space-between !important;
}
.alignCard {
  position: relative;
  top: -55px;
}
.alignCard1 {
  position: relative;
  top: 0px;
}
@media only screen and (min-width: 763px) and (max-width: 992px) {
  .alignCard1 {
    position: relative;
    top: 50px;
  }
}
@media only screen and (min-width: 320px) and (max-width: 762px) {
  .alignCard1 {
    position: relative;
    top: 34px;
  }
}
.onboarding-header-progress {
  /* width: 50%; */
  display: flex;
  align-items: center;
  justify-content: center;
}
.onboarding-header-progress-text {
  color: white !important;
  font-size: 10px;
  width: 100px !important;
}

.onboarding-card-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.02);
  cursor: pointer;
}

.onboarding-card-item-text {
  font-size: 15px;
  text-transform: capitalize;
  margin: 0;
}

.onboarding-icon {
  font-size: 45px;
  color: #4267b2;
}

.onboarding-badge {
  top: -5px;
  left: 0px;
  position: relative;
  background-color: #ffe44d;
  border-radius: 50%;
  padding: 1px 5px;
  font-size: 12px;
  color: black;
}

.onboarding-header-text {
  width: 100%;
  text-align: center;
  color: white;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 700;
}

.onboarding-shake-animation {
  float: right;
  margin-top: 10px;
}

.onboarding-shake-animation:hover {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
  /* -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite; */
}

@keyframes onboardingCardAnimationHide {
  100% {
    visibility: hidden;
    opacity: 0;
    width: 0;
    height: 0;
  }
  /* 90% {
    transform: translate3d(-1px, 0, 0);
  }

  80% {
    transform: translate3d(2px, 0, 0);
  }
  70% {
    transform: translate3d(-4px, 0, 0);
  }
  60% {
    transform: translate3d(4px, 0, 0);
  } */
}

@keyframes onboardingCardAnimation {
  100% {
    visibility: visible;
    opacity: 1;
  }
  0% {
    opacity: 0;
    transform: translate3d(-1px, 0, 0);
  }
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
