@import "../node_modules/bootstrap/scss/bootstrap.scss";
@import "./scss/style.scss";

@import "./scss/css/vendor/bootstrap.min.css";
@import "./scss/css/vendor/bootstrap.rtl.only.min.css";

@import "./scss/css/vendor/materialdesignicons.css";
@import "./scss/css/vendor/materialdesignicons.min.css";

// @import './scss/css/sass/themes/gogo.light.blue.scss'
